
<section
  class="start"
  (click)="speak('SightGuide !')"
  [routerLink]="'exploring'"
>
  <div class="center">
    <!-- <h1>SightGuide</h1> -->
    <div class="icon-loader"></div>
    <!-- <img
      *ngIf="!isInit"
      src="../../assets/ic_launcher.png"
      (click)="speak('Exploring now'); isInit = true"
    /> -->
    <!-- <iframe onload="waitForVideo()"
    (click)="waitForVideo()"
    *ngIf="!init"
      class="video"
      src="https://www.youtube-nocookie.com/embed/d-T3Su8SKuE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autoplay autoplay="true">
    </iframe> -->
    <!-- <video
      class="video"
      controls
      autoplay="true"
      autoplay
      height="auto"
      (click)="waitForVideo()"
      *ngIf="!init"
    >
      <source    class="video"
        (click)="waitForVideo()"
        *ngIf="!init"
        src="https://www.youtube-nocookie.com/embed/d-T3Su8SKuE"
      />
      I'm sorry; your browser doesn't support HTML5 video with MP4 with H.264.
    </video> -->
    <!-- <br />
    <br />
    <button class="init" *ngIf="!isInit" (click)="speak(''); isInit = true">
      <h3>Click here,</h3>
      <h3>to explore your world</h3>
    </button> -->
    <br />
    <button class="width-button" (click)="speak('exploring')">
      Explore your World
    </button>
    <div id="imprint">... like {{counter}} people did before you</div>
    <br />
    <!-- <button class="width-button" (click)="downloadFile('www.SightGuide.app', 'www.SightGuide.app')">
      SaveToScreen
    </button> <br /> -->
    <!-- <a href="../../assets/SightGuide.webloc" download="SightGuide.html"><button class="width-button">Save-link</button></a> -->

    <br />

    <div id="imprint bottom">
      <a
        class="default"
        target="_blank"
        href="https://www.sightguide.app/Impressum-SightGuide.pdf"
        >Impressum</a
      >
    </div>
    <br />
    <div id="donate-button-container">
      <a
        href="https://www.paypal.com/donate/?hosted_button_id=VJRVC4Y4Y9QYL"
        target="_self"
      >
        <div id="donate-button">
          <img src="./assets/btn_donate_SM.gif" />
        </div>
      </a>
    </div>
  </div>
</section>
