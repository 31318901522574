<!-- <div (click)="getOpenMapsLink()"> -->
  <div>
  <span *ngIf="false">
    <button>previous</button>
    <button>play</button>
    <button>next</button>
  </span>
  <h2>{{ headline }}</h2>
  <div *ngIf="extmetadata && extmetadata.Artist">
    <sub
      >Artist: <span innerHTML="{{ extmetadata.Artist.value }}"></span></sub
    ><br />
    <sub>UsageTerms: {{ extmetadata.UsageTerms.value }}</sub
    ><br />
    <sub>License: {{ extmetadata.License.value }}</sub>
    <br />
  </div>
  <div class="row" *ngIf="wikiImage">
    <div class="col-12"><img [src]="wikiImage" />
    </div>
  </div>
  <br>
  <div class="details">
    <div>
      <!-- <a target="_blank" href="{{ getOpenMapsLink() }}">
        <i class="fas fa-2x fa-location-arrow"></i>
      </a> -->
    </div>

    <div class="infoText" innerHTML="{{ infoText }}">
    </div>
  </div>
</div>
