 <!-- <video *ngIf="!isMobileApp" id="video" autoplay="autoplay" loop="loop" muted="muted" playsinline="true" preload="auto" src="./assets/boot.mp4">
</video> -->
<!-- <iframe *ngIf="isMobileApp" src="https://www.youtube-nocookie.com/embed/XNtc_U-4tHc?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen fullscreen autoplay></iframe> -->
<div *ngIf="false"
  id="imprint-main"
  [class.bottom]="!isMobileApp"
  [class.lastbottom]="isMobileApp"
>

  <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#ImpressumModal">
    Impressum
  </button> -->
  <div >
    <a
      id="banner"
      href="https://play.google.com/store/apps/details?id=io.codespirit.sightguide.app&amp;pcampaignid=sightguide.app"
      ><br _ngcontent-cfc-c6="" /><img
        _ngcontent-cfc-c6=""
        alt="Jetzt bei Google Play"
        src="../../assets/google-play-badge.png"
        style="height: 58px; max-width: auto"
    /></a>
  </div>
  <a
    class="button"
    target="_blank"
    href="https://www.sightguide.app/Impressum-SightGuide.pdf"
    >Impressum</a
  >
</div>
<router-outlet></router-outlet>

