import { ApiRequestsService } from 'src/services/api-requests.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent {
  TTS: SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
  counter: any;

  constructor(private service: ApiRequestsService) {
    this.service.getFanCalls()
    .subscribe((data) => {
      this.counter = data;
    });
  }

  speak(text) {
    if (speechSynthesis) {
      const voices = speechSynthesis.getVoices();
      voices.forEach((value, index) => {
        if (value.toString() === 'en-AU') {
          this.TTS.voice = voices[index];
          return;
        }
      });
      this.TTS.text = text;
      speechSynthesis.speak(this.TTS);
    }
  }

}
